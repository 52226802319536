.dietary-overview h1 {
  margin-left: 18px;
}
.dietary-overview table {
  padding: 18px 36px;
  width: 100%;
}
.dietary-overview table th {
  text-align: left;
}

/*# sourceMappingURL=dietary-overview.css.map */
