.hover-button {
  background: var(--button-color);
  height: 54px;
  width: 54px;
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: sticky;
  bottom: 16px;
  left: calc(100% - 62px);
}
.hover-button p {
  margin: 0;
  padding: 0;
  font-size: 4em;
  line-height: 48px;
}
.hover-button img {
  width: 30px;
}

.hover-button:hover {
  box-shadow: 3px 3px 7px darkgray;
  background: #5598ff;
}

/*# sourceMappingURL=hover-button.css.map */
