.seating-header {
  position: absolute;
  width: calc(100% - 416px);
  min-width: 320px;
  right: 0;
  top: 0;
  z-index: 1;
  margin-left: 400px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-around;
  background: linear-gradient(to left, rgb(238, 239, 245), rgb(250, 250, 250));
}
.seating-header .grower {
  flex-grow: 1;
}

/*# sourceMappingURL=seating-header.css.map */
