.click-to-show .popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
}
.click-to-show .popup .popup-wrapper {
  min-width: 450px;
  min-height: 550px;
  padding: 16px;
}

/*# sourceMappingURL=click-to-show.css.map */
