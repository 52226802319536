.save-to-backend-btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.signup-form-page {
  position: relative;
  padding: 20px;
  padding-bottom: 40px;
  min-height: calc(100% - 180px);
  width: calc(100% - 40px);
}
.signup-form-page .new-question-form h2 {
  margin: 0;
  padding-bottom: 28px;
}
.signup-form-page .new-question-form > * {
  margin-bottom: 20px;
}
.signup-form-page .question {
  position: relative;
  margin: 40px 0;
  padding: 20px;
  background: white;
}
.signup-form-page .question h2 {
  margin: 10px 0;
}
.signup-form-page .question .question-text {
  width: 100%;
  font-size: 1.2em;
  font-weight: lighter;
  margin-bottom: 16px;
  outline: none;
  border: none;
  border-bottom: 1px solid lightgrey;
  padding: 4px;
}
.signup-form-page .question .question-type {
  width: 100%;
  max-width: 350px;
}
.signup-form-page .question .delete-question {
  position: absolute;
  border-radius: 100%;
  width: 22px;
  top: 8px;
  right: 8px;
  background: #004699;
  cursor: pointer;
}
.signup-form-page .question .delete-question:hover {
  box-shadow: 0 0 6px grey;
}

/*# sourceMappingURL=signup-form-page.css.map */
