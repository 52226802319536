.table-search-bar {
  position: sticky;
  bottom: 45px;
  width: 450px;
  border-radius: 8px;
  border: none;
  padding-left: 8px;
  font-size: 1.1em;
}

/*# sourceMappingURL=table-search-bar.css.map */
