.client-event-package h3 {
  margin: 0;
}
.client-event-package .client-package-description {
  white-space: pre-wrap;
  line-height: 125%;
  margin: 0;
}

/*# sourceMappingURL=client-event-package.css.map */
