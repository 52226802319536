.selected-rect-movable {
  position: absolute;
  margin: -1px;
  border: 1px solid #339af0;
  z-index: 16;
  transition: width 300ms, height 300ms;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.selected-rect-movable.dragging {
  cursor: grabbing;
  background: rgba(51, 154, 240, 0.2);
}

/*# sourceMappingURL=selected-tables-rect-movable.css.map */
