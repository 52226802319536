.event-export .export-room-wrapper {
  position: relative;
}
.event-export .export-room-wrapper .room-page .room-wrapper {
  transform-origin: 0 0 !important;
  transform: scale(0.3) !important;
}
.event-export .export-room-wrapper .room-page .room {
  width: 100%;
  transform-origin: 0 0 !important;
  transform: none !important;
}
@media print {
  .dont-print {
    display: none;
  }
}

/*# sourceMappingURL=event-export.css.map */
