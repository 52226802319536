.signup-page {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  overflow: auto;
}
.signup-page main {
  padding: 20px;
  padding-bottom: 40px;
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 0 auto;
  background: white;
  box-shadow: 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.35), 0 0.75rem 1rem 0 rgba(0, 0, 0, 0.05);
}
.signup-page main p {
  margin-top: 45px;
  margin-bottom: 45px;
  font-size: 1.2em;
}
.signup-page main h3 {
  margin-bottom: 0;
  font-size: 1.4em;
}
.signup-page main .question {
  border-bottom: 1px solid black;
  padding: 16px;
  margin-bottom: 16px;
}
.signup-page main .question.no-bot-border {
  border-bottom: none;
}
.signup-page main input {
  width: 100%;
  font-size: 1.2em;
  height: 45px;
  padding: 8px;
  outline: none;
  border: none;
  border-bottom: 1px solid lightgrey;
}
.signup-page main input:focus {
  border-bottom: 1px solid #4285f4;
}
.signup-page main .simple-button {
  margin-top: 55px;
}

/*# sourceMappingURL=signup-page.css.map */
