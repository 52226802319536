.selected-rect-seating {
  position: absolute;
  margin: -1px;
  border: 1px solid #339af0;
  z-index: 16;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.selected-rect-seating .status-text {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #339af0;
  font-size: 22px;
  font-weight: 600;
}

/*# sourceMappingURL=selected-tables-rect-seating.css.map */
