.popup-seating-table {
  background: white;
  border: 1px solid black;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1rem 1.5rem 0 rgba(0, 0, 0, 0.35), 0 0.25rem 0.2rem 0 rgba(0, 0, 0, 0.15);
  z-index: 4;
}
.popup-seating-table .seat-count-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

/*# sourceMappingURL=popup-seating-table.css.map */
