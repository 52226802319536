.simple-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.simple-checkbox.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.simple-checkbox .square {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.simple-checkbox .square img {
  width: 12px;
  height: 12px;
  transform: translateX(-0.5px);
}
.simple-checkbox.checked .square {
  background: #2a42b7;
}
.simple-checkbox-label {
  user-select: none;
}

/*# sourceMappingURL=simple-checkbox.css.map */
