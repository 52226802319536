.waiter-list-page {
  width: 100%;
  height: initial;
}
.waiter-list-page .waiter-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 2000px;
  width: 100%;
}
.waiter-list-page .waiter-list table {
  max-width: calc(50% - 32px);
  border-collapse: collapse;
}
.waiter-list-page .waiter-list table th.title {
  font-size: 1.5em;
  text-align: center;
}
.waiter-list-page .waiter-list table td::selection, .waiter-list-page .waiter-list table b::selection {
  background: transparent;
}
.waiter-list-page .waiter-list table tr.drag-selected {
  background: rgba(12, 12, 76, 0.2);
}
.waiter-list-page .waiter-list table .waiter-input-field:hover {
  background: rgba(12, 12, 76, 0.2);
}
.waiter-list-page .waiter-list table td {
  border: 1px solid black;
  min-width: 45px;
}
.waiter-list-page .waiter-list table .filler {
  border: none;
}
.waiter-list-page .waiter-list table .group-end {
  font-weight: bold;
  border: 2px solid black;
}
.waiter-list-page .waiter-list table .group-header td {
  background: lightgrey;
}
.waiter-list-page .waiter-list table .group-header .waiter-field {
  width: 200px;
}
.waiter-list-page .waiter-list .opsummering {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  max-width: calc(50% - 32px);
}
.waiter-list-page .waiter-list .opsummering > * {
  width: 33%;
}
.waiter-list-page .waiter-list .dishwash h4 {
  color: red;
  text-decoration: underline;
  margin: 0;
}
.waiter-list-page .waiter-list .dishwash p {
  margin: 0;
}
.waiter-list-page .waiter-list .dishwash input {
  margin-bottom: 0;
}

@media print {
  .left-menu, .hint-to-export, .header, .export-link, .admin-header {
    display: none !important;
  }
  .waiter-list-page {
    width: 100% !important;
    height: 2200px !important;
    overflow-y: visible !important;
    font-size: 1em !important;
    zoom: 50%;
  }
  .waiter-list-page .waiter-list {
    width: 100% !important;
    height: 100% !important;
  }
  .waiter-list-page .waiter-list table {
    width: 50% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .waiter-list-page input {
    border: none;
  }
}
@media print {
  html, body, #root, .admin-page-wrapper, .scrollable, .event-page-wrapper {
    height: initial !important;
    overflow: initial !important;
  }
}

/*# sourceMappingURL=waiter-list.css.map */
