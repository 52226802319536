.proposal-option {
  border-radius: 4px;
}
.proposal-option .printer-friendly-paragraphs {
  page-break-inside: avoid;
}
.proposal-option .client-paragraph-description {
  white-space: pre-wrap;
  line-height: 125%;
  margin: 0;
}
.proposal-option .option-header {
  display: flex;
  gap: 16px;
  padding: 0 !important;
}
.proposal-option p {
  white-space: pre-wrap;
  margin: 0;
}
.proposal-option .option-controls {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px;
  margin-top: 8px;
}
.proposal-option .option-controls .grower {
  flex-grow: 1;
}
.proposal-option .delete {
  cursor: pointer;
}
.proposal-option .delete:hover {
  fill: red;
}

/*# sourceMappingURL=proposal-option.css.map */
