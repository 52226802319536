.color-picker {
  font-size: 16px;
  border: 1px solid #ccc;
}
.color-picker .colors-wrapper {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
.color-picker .color-picker-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.color-picker .color-picker-color.selected {
  border: 2px solid #000;
}

/*# sourceMappingURL=color-picker.css.map */
