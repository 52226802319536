.proposal-package .edit {
  height: 20px;
  margin-right: 12px;
  cursor: pointer;
}
.proposal-package .package-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.proposal-package .package-header h2, .proposal-package .package-header h3 {
  margin: 0;
  display: flex;
  align-items: center;
}
.proposal-package .package-header img {
  height: 18px;
  margin-left: 12px;
}
.proposal-package .package-header .move-btn {
  height: 16px !important;
}
.proposal-package .delete {
  cursor: pointer;
}
.proposal-package .printer-friendly-paragraphs {
  page-break-inside: avoid;
}
.proposal-package .editable {
  cursor: pointer;
  min-height: 1rem;
  min-width: 180px;
  padding: 4px;
}
.proposal-package .editable .empty {
  color: gray;
  font-style: italic;
}
.proposal-package .editable:hover {
  background-color: #f0f0f0;
}
.proposal-package .package-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.proposal-package .package-details .package-description-wrapper {
  display: flex;
  align-items: flex-start;
}
.proposal-package .package-details .package-description-wrapper .package-description {
  white-space: pre-wrap;
  line-height: 125%;
  margin: 0;
}
.proposal-package .package-details .package-options {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.proposal-package .package-details .package-options input[type=text], .proposal-package .package-details .package-options textarea {
  width: 100%;
  padding: 8px 16px;
}
.proposal-package .package-details hr {
  margin: 12px 0;
  border-top: 1px solid #d3d3d3;
}
.proposal-package .package-details .option-header {
  display: flex;
  align-items: center;
  gap: 8px;
}
.proposal-package .package-details .option-header h2 {
  margin: 0;
}
.proposal-package .delete-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.only-show-in-print {
  display: none;
}

.printable-wrapper button, .printable-wrapper .simple-button, .printable-wrapper .hide-in-print {
  display: none !important;
}
.printable-wrapper p {
  page-break-inside: avoid;
}
.printable-wrapper .only-show-in-print {
  display: block;
}

/*# sourceMappingURL=proposal-package.css.map */
