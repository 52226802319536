.import-guests-button {
  width: 260px;
}

.import-guest-list {
  width: calc(100% - 100px) !important;
  max-width: 950px;
  min-height: 500px;
  z-index: 5;
}
.import-guest-list .guest-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
}
.import-guest-list .import-method-selection {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.import-guest-list .import-method-selection p {
  font-size: 1.2em;
  padding: 0;
  margin: 0;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  color: lightgrey;
  border-bottom: 2px solid lightgrey;
}
.import-guest-list .import-method-selection p:hover {
  font-weight: bold;
  color: black;
}
.import-guest-list .import-method-selection p.selected {
  border-bottom: 2px solid #164d4f;
  color: #164d4f;
  font-weight: bold;
}
.import-guest-list .manual-input .input-field {
  margin-bottom: 8px;
}
.import-guest-list table {
  width: 100%;
  border-collapse: collapse;
}
.import-guest-list tr:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=import-guest-list.css.map */
