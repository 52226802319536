.bottom-page-popout {
  position: absolute;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  width: 300px;
  background: white;
}
.bottom-page-popout.open {
  padding-bottom: 30px;
}
.bottom-page-popout .header {
  background: #2a42b7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px 16px 0 0;
  padding: 4px 32px;
  margin-bottom: 4px;
}
.bottom-page-popout .header p {
  font-weight: bold;
  color: white;
}
.bottom-page-popout .header img {
  cursor: pointer;
}
.bottom-page-popout .header img:hover {
  border-bottom: 1px solid white;
}
.bottom-page-popout .header.closed {
  cursor: pointer;
}

/*# sourceMappingURL=bottom-page-popout.css.map */
