.proposal-template-page section {
  padding: 32px 16px;
}
.proposal-template-page section h2 {
  margin: 0;
  margin-bottom: 16px;
}
.proposal-template-page .header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.proposal-template-page .header .text-header {
  max-width: 500px;
}
.proposal-template-page .document-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.proposal-template-page .proposal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.2), 0px 2px 4px -2px rgba(0, 0, 0, 0.15), 0px 4px 10px -4px rgba(0, 0, 0, 0.15);
  width: 200px;
  height: 320px;
  align-self: stretch;
  position: relative;
}
.proposal-template-page .proposal p {
  flex-grow: 1;
}
.proposal-template-page .proposal hr {
  width: 100%;
}
.proposal-template-page .proposal .simple-button {
  align-self: stretch;
}

/*# sourceMappingURL=proposal-template-page.css.map */
