.file-upload-page {
  width: 100%;
}
.file-upload-page .open-window-button {
  cursor: pointer;
  background: var(--app-dark-blue);
  border-radius: 100%;
  height: 44px;
  width: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.file-upload-page .open-window-button img {
  height: 24px;
  width: 24px;
  margin: 0 auto;
}
.file-upload-page .open-window-button:hover {
  background: var(--dark-blue);
}
.file-upload-page .popup-wrapper {
  margin-top: 200px !important;
  width: 450px;
  border-radius: 8px;
}
.file-upload-page .cc-file-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  padding-bottom: 25px !important;
}
.file-upload-page .cc-file-upload h1 {
  height: 55px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  text-align: center;
  padding: 0;
  padding-top: 25px;
  margin: 0;
  background: var(--app-dark-blue);
  color: white;
  font-weight: 400;
}
.file-upload-page .cc-file-upload .upload-field {
  color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 70px);
  border: 1px solid var(--dark-blue);
  border-radius: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.file-upload-page .cc-file-upload .upload-field img {
  width: 34px;
  pointer-events: none;
}
.file-upload-page .cc-file-upload .upload-field p {
  margin-top: 15px;
  pointer-events: none;
  text-align: center;
}
.file-upload-page .cc-file-upload .dragging {
  border: 1px dotted var(--severity-level-1) !important;
  background: lightgreen;
}
.file-upload-page .cc-file-upload .file-selector {
  display: none;
}
.file-upload-page .cc-file-upload .styled-file-selector {
  cursor: pointer;
  text-decoration: underline;
}
.file-upload-page .cc-file-upload .styled-file-selector h5 {
  font-weight: 400;
  margin: 0;
}
.file-upload-page .cc-file-upload .styled-file-selector.dragging {
  pointer-events: none;
}
.file-upload-page .cc-file-upload .file-list {
  width: 100%;
}
.file-upload-page .cc-file-upload .file-list table {
  border-collapse: collapse;
  width: 100%;
}
.file-upload-page .cc-file-upload .file-list table td, .file-upload-page .cc-file-upload .file-list table th {
  padding-top: 2px;
  padding-bottom: 2px;
}
.file-upload-page .cc-file-upload .file-list table td img, .file-upload-page .cc-file-upload .file-list table th img {
  max-width: 250px;
}
.file-upload-page .cc-file-upload .file-list table thead th {
  border-bottom: 1px solid var(--font-dark);
}

/*# sourceMappingURL=k-file-drop.css.map */
