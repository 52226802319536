/* NewCustomerPopup.scss */
.new-customer-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.new-customer-popup {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 600px;
  max-width: 90%;
}
.new-customer-popup .columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.new-customer-popup .columns .column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 48%;
}
.new-customer-popup .columns .column h4 {
  margin: 0;
  margin-bottom: 8px;
}
.new-customer-popup .buttons {
  display: flex;
  gap: 10px;
}

/*# sourceMappingURL=new-customer.css.map */
