.simple-button {
  border: none;
  font-weight: 1000;
  border-radius: 8px;

  flex-shrink: 1;

  background: black;
  color: white;

  padding: 12px 22px;

  cursor: pointer;
  transition: opacity .2s;

  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  img {
    max-height: 100%;
  }

  &.secondary {
    background: transparent;
    color: black;
    border: 1px solid black;
    font-weight: 400;

    &:hover {
      opacity: 1;
      border: 1px solid black;
    }
  }
  &.just-text {
    background: none;
    color: black;
    padding: 0;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    &:hover {
      opacity: 0.6;
    }
  }
}

.simple-button:hover, .simple-button.always-selected {
  opacity: 0.6;
}

.simple-button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.simple-button.loading {
  cursor: progress;
}


.simple-button.big {
  width: 100%;
  font-size: 1.3em;
}

.simple-button.red {
  background: var(--button-color-red);
}

.simple-button.red:hover {
  background: var(--button-hover-color-red);
  transition: background-color .2s;
}

.simple-button.round {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  padding: 0;
  font-size: 30px;
  line-height: 30px;
}

.simple-button.always-selected {
  cursor: auto;
}

.simple-button.dangerous {
  background: #5b4e4e;
}