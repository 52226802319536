.venue-page-wrapper {
  display: flex;
  gap: 0.5em;
  justify-content: stretch;
  width: calc(100% - 2em);
  height: calc(100vh - 2em);
  background: whitesmoke;
  padding: 1em;
}
.venue-page-wrapper .phone-top-bar {
  display: none;
}
.venue-page-wrapper .phone-top-bar .open-phone-menu-btn {
  height: 48px;
}
@media (max-width: 768px) {
  .venue-page-wrapper {
    flex-direction: column;
  }
  .venue-page-wrapper .phone-top-bar {
    display: block;
  }
  .venue-page-wrapper .left-menu {
    position: absolute;
    height: calc(100vh - 1em) !important;
    width: calc(100% - 1em) !important;
    top: 0;
    left: 0;
    border-radius: 0;
    z-index: 4;
    gap: 1em;
  }
  .venue-page-wrapper .left-menu .close-phone-menu-btn {
    display: block !important;
    background: #164d4f;
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 50%;
    padding: 4px;
  }
  .venue-page-wrapper .left-menu.closed {
    display: none;
  }
}
.venue-page-wrapper .left-menu {
  width: 240px;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: white;
  border-right: 1px solid lightgrey;
}
.venue-page-wrapper .left-menu .close-phone-menu-btn {
  display: none;
}
.venue-page-wrapper .left-menu .grower {
  flex-grow: 1;
}
.venue-page-wrapper .left-menu .header-wrapper {
  font-weight: 100;
  margin: 0;
  cursor: pointer;
  margin-bottom: 16px;
  text-align: center;
}
.venue-page-wrapper .left-menu .header-wrapper h2, .venue-page-wrapper .left-menu .header-wrapper h5 {
  margin: 0;
}
.venue-page-wrapper .left-menu .menu-item {
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  border-left: 2px solid transparent;
  padding: 6px 16px;
  color: black;
}
.venue-page-wrapper .left-menu .menu-item.selected {
  border-left: 2px solid #2a42b7;
  font-weight: bold;
  background: rgba(42, 66, 183, 0.1);
}
.venue-page-wrapper .left-menu .menu-item:not(.selected):hover {
  background: rgba(42, 66, 183, 0.1);
}
.venue-page-wrapper .scrollable {
  height: 90vh;
  overflow-y: auto;
  border-radius: 8px;
  margin-left: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10vh;
  flex-grow: 1;
}

@media print {
  .venue-page-wrapper {
    padding: 0;
  }
  .venue-page-wrapper .left-menu {
    display: none;
  }
  .venue-page-wrapper .phone-top-bar {
    display: none !important;
  }
  .venue-page-wrapper .scrollable {
    height: auto;
    overflow-y: visible;
    border-radius: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    flex-grow: 1;
  }
}

/*# sourceMappingURL=venue-page-wrapper.css.map */
