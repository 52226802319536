.centered-popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(120, 120, 120, 0.94);
  z-index: 3;
}
.centered-popup .close-popup-cross-btn {
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  background: black;
  border-radius: 100%;
  padding: 8px;
}
.centered-popup .close-popup-cross-btn:hover {
  background: lightgrey;
}
.centered-popup .popup-wrapper {
  position: absolute;
  top: 2vh;
  left: 50%;
  transform: translateX(-50%);
  max-height: calc(94vh - 4vh);
  background: white;
  padding: 2vh;
  overflow-y: auto;
  margin: 0 auto;
  margin-top: 2% !important;
  border-radius: 4px;
}
.centered-popup .hor {
  width: 100%;
  display: flex;
}
.centered-popup .hor > * {
  margin-right: 8px;
}

/*# sourceMappingURL=centered-popup.css.map */
