.export-to-pdf-temporary button, .export-to-pdf-temporary .simple-button, .export-to-pdf-temporary .hide-in-print, .export-to-pdf-temporary .single-char-button {
  display: none !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
}
.export-to-pdf-temporary p {
  page-break-inside: avoid;
}
.export-to-pdf-temporary .only-show-in-print {
  display: block;
}

.only-show-in-print {
  display: none;
}

/*# sourceMappingURL=export-to-pdf.css.map */
