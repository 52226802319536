.loading-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 155px;
}
.loading-page h4 {
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
}
.loading-page h4 span {
  color: #004aad;
}
.loading-page .disc, .loading-page .ground {
  position: absolute;
  bottom: 0;
}
.loading-page .disc {
  background: #fc6701;
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  animation: disc-animation 700ms alternate infinite ease;
}
@keyframes disc-animation {
  0% {
    bottom: -15px;
    transform: rotateX(80deg);
  }
  20% {
    transform: rotateX(50deg);
  }
  100% {
    bottom: 70px;
    transform: rotateX(620deg);
  }
}
.loading-page .ground {
  height: 170px;
  width: 120px;
  background: #4285f4;
  border: 1px solid black;
  transform: translateY(50%) rotateX(80deg) rotateZ(30deg);
}

/*# sourceMappingURL=loading-page.css.map */
