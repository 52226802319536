.guest-list {
  width: 100%;
}
.guest-list h3 {
  margin: 0;
  margin-bottom: 4px;
}
.guest-list .table-wrapper {
  overflow-y: auto;
}
.guest-list .table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  cursor: pointer;
}
.guest-list .table-wrapper table th {
  text-align: left;
}
.guest-list .table-wrapper tr.selected {
  background: lightgray;
}
.guest-list .search-bar {
  width: calc(100% - 30px);
  margin-bottom: 8px;
}

/*# sourceMappingURL=guest-list.css.map */
