.event-home {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.event-home hr {
  margin: 30px 0;
}
.event-home .horizontal {
  display: flex;
  padding-top: 16px;
}
.event-home .horizontal > * {
  margin-right: 4px;
}
.event-home .title-wrapper h1, .event-home .title-wrapper h2, .event-home .title-wrapper h3, .event-home .title-wrapper h4 {
  margin: 0;
  padding: 0;
}
.event-home .title-wrapper h4 {
  opacity: 0.8;
}
.event-home .door-list-popup {
  width: 800px;
}
.event-home .door-list-popup table {
  width: 100%;
}
.event-home .door-list-popup table th {
  text-align: left;
}
.event-home .rooms {
  margin-top: 30px;
  margin-bottom: 30px;
}
.event-home .rooms h2 {
  margin: 0;
}
.event-home .seating-overview-wrapper {
  background: white;
  padding: 8px;
  margin: 20px 0;
}
.event-home .seating-overview-wrapper h2 {
  margin: 0;
  margin-bottom: 8px;
}
.event-home .seating-overview-wrapper table {
  margin-bottom: 8px;
}
.event-home .seating-overview-wrapper table th {
  text-align: left;
}
.event-home .seating-overview-wrapper table td {
  padding-left: 8px;
}
.event-home .seating-overview-wrapper a {
  font-size: 1.2em;
}
.event-home .power-table {
  height: 500px;
}
.event-home .seating-overview-wrapper {
  gap: 64px;
}

/*# sourceMappingURL=event-home.css.map */
