.guest-management-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 95vh;
}
.guest-management-page .guest-management-table-wrapper {
  position: relative;
  width: 100%;
  overflow-y: auto;
  height: calc(95vh - 120px);
}
.guest-management-page .guest-management-table-wrapper table {
  margin-bottom: 15px;
  border-collapse: collapse;
  width: 100%;
  min-width: 500px;
}
.guest-management-page .guest-management-table-wrapper table th {
  position: sticky;
  top: 0;
  cursor: pointer;
  background: white;
  padding-right: 4px;
  padding-left: 5px;
  text-align: left;
}
.guest-management-page .guest-management-table-wrapper table th svg {
  width: 12px;
  display: none;
  margin-left: 2px;
}
.guest-management-page .guest-management-table-wrapper table th svg.focus {
  display: inline-block;
}
.guest-management-page .guest-management-table-wrapper table th svg.desc {
  transform: rotate(180deg);
}
.guest-management-page .guest-management-table-wrapper table tbody tr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid transparent;
}
.guest-management-page .guest-management-table-wrapper table tbody tr .delete-icon-td {
  width: 16px;
  opacity: 0;
}
.guest-management-page .guest-management-table-wrapper table tbody tr .delete-icon-td .delete-icon {
  border: 1px solid transparent;
  width: 14px;
  padding: 2px;
}
.guest-management-page .guest-management-table-wrapper table tbody tr .delete-icon-td:hover .delete-icon {
  border: 1px solid red;
  border-radius: 100%;
}
.guest-management-page .guest-management-table-wrapper table tbody tr td {
  padding-right: 6px;
  padding-left: 6px;
}
.guest-management-page .guest-management-table-wrapper table tbody tr td .count-icon {
  display: inline-block;
  font-family: "Arima Madurai", "Raleway", "sans-serif";
  border-radius: 100%;
  margin-left: 3px;
  margin-right: 3px;
}
.guest-management-page .guest-management-table-wrapper table tbody tr:hover {
  border-bottom: 1px solid black;
  background: #004699;
  color: white;
}
.guest-management-page .guest-management-table-wrapper table tbody tr:hover .delete-icon-td {
  background: white;
  opacity: 1;
}
.guest-management-page .guest-management-table-wrapper table input {
  height: 32px !important;
}
.guest-management-page .guest-management-table-wrapper table input[type=checkbox] {
  width: 45px;
}
.guest-management-page .guest-management-table-wrapper .save-button {
  position: sticky;
  bottom: 0;
}

/*# sourceMappingURL=guest-management-page.css.map */
