.element-context-menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.element-context-menu h2 {
  margin: 0;
}

/*# sourceMappingURL=element-context-menu.css.map */
