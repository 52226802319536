.table {
  position: absolute;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.table p {
  height: 20px;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 0;
}
.table label {
  pointer-events: none;
}
.table .table-name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  font-weight: bold;
  cursor: pointer;
  background: white;
  padding: 2px;
}
.table .seating-label-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table .seating-label-wrapper .seating-label.active_seats {
  border-bottom: 1px solid black;
}
.table .seating-label-wrapper .seating-label.max_seating {
  opacity: 40%;
}
.table.selected {
  border-color: #339af0;
}
.table .seats {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 0;
  height: 100%;
}
.table .chair {
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1.5px 2px 0 rgba(0, 0, 0, 0.2);
}
.table.Round {
  border-radius: 100%;
}
.table.Round .table-name {
  position: absolute;
  transform: translateX(-50%) translateY(calc(-110% - 25px));
  font-weight: bold;
  background: white;
  padding: 1px;
  margin-left: 1px;
}
.table.Sofa {
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
}
.table.Sofa .table-name {
  transform: translateX(-50%) translateY(80%);
}
.table.Cinema .seating-label-wrapper {
  opacity: 2 !important;
}
.table.Cinema .seating-label-wrapper .span {
  opacity: 2 !important;
  color: black !important;
}
.table.Cinema .chair {
  border-radius: 2px !important;
}
.table:hover {
  border-width: 2px;
}

/*# sourceMappingURL=room-table.css.map */
