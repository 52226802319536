.multi-input-field {
  position: relative;
  display: flex;
  flex-direction: column;
}
.multi-input-field .input-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(211, 214, 223, 0.7);
  border: 1px solid transparent;
  border-radius: 4px;
}
.multi-input-field .input-title-wrapper .title-label {
  margin: 0;
  font-size: 18px;
  font-weight: 100;
}
.multi-input-field .input-title-wrapper .error {
  color: red;
}
.multi-input-field .input-title-wrapper .input-field-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  justify-content: space-evenly;
}
.multi-input-field .input-title-wrapper .input-field-wrapper label {
  border-radius: 12px;
  padding: 4px 3px;
  color: white;
  background: #4285ff;
  margin-left: 4px;
}
.multi-input-field .input-title-wrapper .input-field-wrapper input {
  border: none;
  outline: none;
  flex-shrink: 1;
}
.multi-input-field .input-title-wrapper:hover {
  border: 1px solid #7ca2f8;
}

.input-field.checkbox input {
  width: auto;
}

/*# sourceMappingURL=multi-simple-input.css.map */
