.create-new-event {
  width: 600px;
  min-height: 725px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.create-new-event h4 {
  margin: 0;
}
.create-new-event .date-time-selection-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.create-new-event .submit-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.create-new-event .room-guestcount-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
  min-height: 220px;
}
.create-new-event .room-guestcount-wrapper h4 {
  font-weight: bold;
  margin: 0;
  margin-bottom: 4px;
}
.create-new-event .horizontal {
  display: flex;
  gap: 16px;
}
.create-new-event .grower {
  flex-grow: 1;
}
.create-new-event .room-selection {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 250px;
}
.create-new-event .room-selection p {
  border: 1px solid lightgrey;
  padding: 8px;
  cursor: pointer;
}
.create-new-event .room-selection p:hover {
  border: 1px solid black;
}
.create-new-event .room-selection .room-it {
  border: 1px solid lightgrey;
  padding: 8px;
  cursor: pointer;
  min-width: 220px;
  display: flex;
  justify-content: space-between;
}
.create-new-event .room-selection .room-it img {
  display: none;
  background: #3c6273;
  border-radius: 50%;
  padding: 6px;
  height: 12px;
  width: 12px;
}
.create-new-event .room-selection .room-it.selected {
  border: 1px solid black;
  padding: 7px;
}
.create-new-event .room-selection .room-it.selected img {
  display: block;
}

/*# sourceMappingURL=create-new-event.css.map */
