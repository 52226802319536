.color-dropdown {
  position: relative;
}
.color-dropdown .wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  width: 46px;
  height: 26px;
  background: black;
  border-radius: 4px;
  cursor: pointer;
}
.color-dropdown .wrapper .arrow {
  height: 70%;
}
.color-dropdown .selected {
  cursor: pointer;
}
.color-dropdown .menu {
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #000;
  border-radius: 0 0 4px 4px;
}
.color-dropdown .menu .color-option {
  width: 100%;
  padding: 8px 4px;
  border-radius: 4px;
}
.color-dropdown .color-option {
  cursor: pointer;
  height: 12px;
  width: 12px;
}
.color-dropdown .color-option .circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

/*# sourceMappingURL=color-dropdown.css.map */
