.event-settings .horizontal {
  gap: 32px;
}
.event-settings .event-input-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 270px;
}
.event-settings .event-input-fields .simple-button {
  width: 220px;
}
.event-settings .danger-zone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.event-settings .danger-zone h1 {
  margin-bottom: 0;
}
.event-settings .danger-zone subtitle {
  margin-bottom: 30px;
}
.event-settings .danger-zone .simple-button {
  background: #5b4e4e;
}

/*# sourceMappingURL=event-settings.css.map */
