.measuring-tool {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(120, 120, 120, 0.5);
}
.measuring-tool .explain-scale-popup {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 16px;
  border-radius: 4px;
}

/*# sourceMappingURL=measuring-tool.css.map */
