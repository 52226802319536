html, body, #root {
  width: 100%;
  height: 100%;

  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Leaguespartan", "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
body h1, body h2, body h3, body h4, body h5 {
  font-weight: 600;
}
body input {
  font-family: "Leaguespartan", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
body textarea {
  font-family: "Leaguespartan", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --background-color: #164d4f;
  --background-content-color: #fc6701;
  --splash: #fc6701;
  --button-color: linear-gradient(to left, #073350, #073370);
  --button-hover-color: rgba(0, 0, 0, 0.6);
  --button-color-red: rgba(153, 6, 0, 0.87);
  --button-hover-color-red: rgba(153, 6, 0, 0.6);
  --primary-color1: rgba(215, 211, 208, 1);
  --primary-color2: rgba(113, 113, 113, 1);
  --primary-color3: rgba(159, 204, 245, 1);
  --primary-color4: rgba(97, 139, 76, 1);
  --primary-color5: rgba(207, 222, 72, 1);
  --primary-color6: rgba(189, 92, 47, 1);
  --primary-color7: rgba(253, 107, 68, 1);
  --primary-color8: rgba(242, 119, 225, 1);
}

.horizontal {
  display: flex;
}
.horizontal.center {
  align-items: center;
}

.grower {
  flex-grow: 1;
}

@media print {
  .hide-in-print {
    display: none !important;
  }
}

/*# sourceMappingURL=index.css.map */
