.proposal-page {
  display: flex;
  flex-direction: column;
  position: relative;
}
.proposal-page .customer-wrapper {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  z-index: 4;
  padding: 32px;
  box-shadow: 0 1rem 2.5rem 0 rgba(0, 0, 0, 0.35), 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.25);
}
.proposal-page .no-customer {
  filter: blur(4px);
}
.proposal-page .pdf-iframe {
  width: 100%;
  height: calc(90vh - 260px);
  margin-top: 10px;
}
.proposal-page .add-new-package {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dotted black;
  border-radius: 8px;
  padding: 12px 0;
  margin: 32px 0;
}
.proposal-page .add-new-package .simple-button {
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 600;
}
.proposal-page .document-template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.proposal-page .document-template-header .document-template-title {
  font-size: 24px;
  font-weight: bold;
}
.proposal-page .document-template-header .document-template-actions {
  display: flex;
  gap: 16px;
}
.proposal-page .title {
  margin: 0;
  margin-bottom: 32px;
  font-size: 24px;
  display: flex;
}
.proposal-page .title img {
  margin-right: 12px;
}
.proposal-page .info-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
}
.proposal-page .back-btn {
  margin-bottom: 16px;
}
.proposal-page .item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 800px;
  margin: 0 auto;
}
.proposal-page .item-wrapper hr {
  width: 100%;
}
.proposal-page .item-wrapper .document-intro-image-wrapper {
  display: flex;
  justify-content: center;
  max-width: 250px;
}
.proposal-page .item-wrapper .document-intro-image-wrapper .document-intro-image {
  max-height: 250px;
  max-width: 100%;
}
.proposal-page .item-wrapper .item-content {
  flex-grow: 1;
}
.proposal-page .item-wrapper .btn-wrapper {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.proposal-page .item-wrapper .btn-wrapper img {
  width: 48px;
}
.proposal-page .item-wrapper .btn-wrapper .delete-icon {
  padding: 2px;
  cursor: pointer;
  width: 17px;
}
.proposal-page .item-wrapper .btn-wrapper .package-drop-area {
  flex-grow: 1;
  align-self: stretch;
  margin: 4px;
}
.proposal-page .item-wrapper .btn-wrapper.dragging .package-drop-area {
  border-radius: 6px;
  border: 1px dashed gray;
}
.proposal-page .item-wrapper .btn-wrapper.dragging .package-drop-area:hover {
  border: 1px dashed black;
}
.proposal-page .item-wrapper.customer-info section {
  padding: 16px;
}
.proposal-page .item-wrapper.stretch section {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.proposal-page .item-wrapper.stretch section .horizontal > * {
  flex-grow: 1;
}
.proposal-page .accept-tools {
  display: none !important;
}
.proposal-page .horizontal.center {
  padding: 16px 0;
  justify-content: space-between;
}
.proposal-page .horizontal.token-link .simple-input {
  flex: 8;
}
.proposal-page .horizontal.token-link .simple-button {
  flex: 4;
}
.proposal-page .dragging-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh;
  z-index: 1000;
}
.proposal-page .dragging-overlay.bottom {
  top: auto;
  bottom: 0;
}
.proposal-page .export-popup {
  width: 500px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 8px;
}
.proposal-page .export-popup h2 {
  margin: 0;
  margin-bottom: 22px;
}
.proposal-page .export-popup p {
  margin: 8px 0;
  line-height: 135%;
}
.proposal-page .export-popup .proposal-locked-header, .proposal-page .export-popup .proposal-not-locked-header {
  border-radius: 2px;
  border: 1px solid rgba(0, 129, 30, 0.75);
  background: #ECF6EE;
  padding: 8px;
  font-weight: 600;
  margin: 0;
  color: #00811E;
}
.proposal-page .export-popup .proposal-locked-header img, .proposal-page .export-popup .proposal-not-locked-header img {
  margin-right: 8px;
}
.proposal-page .export-popup .proposal-not-locked-header {
  border-radius: 2px;
  border: 1px solid rgba(228, 111, 0, 0.75);
  background: #FDF4EC;
  color: #E46F00;
}
.proposal-page .export-popup .horizontal {
  align-self: stretch;
  gap: 8px;
  padding: 0;
}
.proposal-page .export-popup .copyaple-link, .proposal-page .export-popup hr {
  align-self: stretch;
  width: 100%;
}
.proposal-page .back-btn {
  width: auto;
  align-self: flex-start;
}
.proposal-page .economy-header {
  display: flex;
  align-items: center;
}

.printable-wrapper button, .printable-wrapper .simple-button, .printable-wrapper .hide-in-print {
  display: none !important;
}
.printable-wrapper .only-show-in-print {
  display: block;
}

/*# sourceMappingURL=proposal-page.css.map */
