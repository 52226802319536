.single-char-button {
  font-weight: 600;
  width: 20px;
  cursor: pointer;
  position: relative;
}
.single-char-button .char-div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2em;
  padding: 2px;
  height: 16px;
  width: 16px;
  background: #0b67e3;
  border-radius: 100%;
  color: white;
  border: 1px solid transparent;
}
.single-char-button .char-div img {
  height: 80%;
  width: 80%;
}
.single-char-button .char-div.open {
  border-radius: 50% 50% 0 0;
  color: black;
  background: white;
  border: 1px solid lightgrey;
}
.single-char-button .char-div:hover {
  color: black;
  background: white;
  border: 1px solid lightgrey;
}
.single-char-button .menu {
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: -1px;
  display: flex;
  flex-direction: column;
  background: #0b67e3;
  color: white;
  border-radius: 0 4px 4px 4px;
}
.single-char-button .menu .option {
  padding: 8px 12px;
}
.single-char-button .menu .option:hover {
  background: white;
  color: black;
  border: 1px solid lightgrey;
  border-radius: 0 4px 4px 4px;
}
.single-char-button .option {
  cursor: pointer;
}

/*# sourceMappingURL=single-char-button.css.map */
