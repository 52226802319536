.input-field {
  position: relative;
  display: flex;
  flex-direction: column;
}
.input-field .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(211, 214, 223, 0.7);
  border: 1px solid transparent;
  border-radius: 4px;
}
.input-field .input-wrapper:hover {
  border: 1px solid #7ca2f8;
}
.input-field .non-selectable {
  cursor: pointer;
}
.input-field .title-label {
  margin: 0;
  font-size: 18px;
  font-weight: 100;
}
.input-field input {
  padding: 0 12px;
  width: calc(100% - 24px);
  border: none;
  height: 40px;
}
.input-field .input-wrapper:hover:not(.disabled) .input-field-icon-wrapper .input-field-icon {
  opacity: 1;
  background: darkblue;
  cursor: pointer;
}
.input-field .input-field-wrapper {
  display: flex;
  align-items: center;
}
.input-field .input-field-wrapper .input-field-icon {
  background: lightgrey;
  opacity: 0.4;
  height: 24px;
  width: 24px;
  position: absolute;
  right: 8px;
  cursor: pointer;
  border-radius: 100%;
}
.input-field .error {
  color: red;
}
.input-field .option-wrapper {
  min-width: calc(100% - 2px);
  width: auto;
  border: 1px solid black;
  position: absolute;
  top: 100%;
  background: white;
  z-index: 99;
  max-height: 250px;
  overflow-y: auto;
}
.input-field .option-wrapper.open-upwards {
  bottom: 48px;
  top: auto;
}
.input-field .drop-option {
  list-style: none;
  font-weight: 400;
  color: black;
  cursor: pointer;
}
.input-field .drop-option:hover {
  border-bottom: 1px solid black;
  font-weight: 700;
}
.input-field .selected-wrapper {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
}
.input-field .selected-wrapper .selected-item {
  border: 1px solid black;
  border-radius: 15px;
  padding: 5px 15px;
  display: flex;
  align-content: center;
  margin-right: 7px;
  margin-bottom: 5px;
  cursor: pointer;
}

.input-field.checkbox input {
  width: auto;
}

/*# sourceMappingURL=input-field-with-options.css.map */
