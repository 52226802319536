.value-key-label {
  display: flex;
}
.value-key-label p {
  padding: 0;
  margin: 0 8px 0 0;
  font-weight: bold;
}

/*# sourceMappingURL=value-key-label.css.map */
