.calendar {
  margin: auto;
}
.calendar .calendar-month-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.calendar .calendar-month-selection .month {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}
.calendar .calendar-month-selection .month img {
  width: 20px;
  height: 20px;
}
.calendar .calendar-month-selection .month.prev img {
  transform: rotate(90deg);
}
.calendar .calendar-month-selection .month.next img {
  transform: rotate(-90deg);
}
.calendar .calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto repeat(7, 100px);
  gap: 5px;
}
.calendar .calendar-day {
  font-weight: bold;
  padding: 5px;
  text-align: center;
}
.calendar .calendar-date {
  padding: 10px;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 22px;
  min-width: 0;
  max-width: 100%;
  cursor: pointer;
}
.calendar .calendar-date:hover {
  background-color: #d0d0d0;
}
.calendar .calendar-date .date-number {
  position: absolute;
  right: 4px;
  top: 4px;
}
.calendar .calendar-date .calendar-event {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}
.calendar .calendar-date .calendar-event h5, .calendar .calendar-date .calendar-event p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
.calendar .calendar-date .calendar-event h5.proposal, .calendar .calendar-date .calendar-event p.proposal {
  background: #F2C94C;
}
.calendar .calendar-date .calendar-event:hover {
  cursor: pointer;
  background: #2a42b7;
}
.calendar .calendar-date .calendar-event:hover h5 {
  color: white;
}
.calendar .calendar-date .calendar-event:hover h5.proposal {
  background: none;
}
.calendar .calendar-date .calendar-event:hover .hover-info {
  display: block;
}
.calendar .calendar-date .calendar-event .hover-info {
  z-index: 2;
  position: absolute;
  width: 250px;
  background: white;
  border: 1px solid lightgrey;
  padding: 16px;
  pointer-events: none;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  display: none;
}
.calendar .calendar-date .calendar-event .hover-info p {
  margin: 0;
  font-size: 14px !important;
}
.calendar .current-month {
  background-color: #f0f0f0;
}
.calendar .other-month {
  opacity: 0.5;
  background-color: #fbfbfb;
}
.calendar .is-today {
  border: 1px solid #2a42b7;
}

/*# sourceMappingURL=calendar.css.map */
