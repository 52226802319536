.loading-animation {
  perspective: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightgrey;
}
.loading-animation p {
  color: red;
}
.loading-animation .spinner {
  width: 30px;
  height: 30px;
  background: var(--background-content-color);
  animation: rotateAnimation 2.5s linear infinite;
  border-radius: 100%;
  position: relative;
  border: 1px solid black;
}
@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }
  30% {
    transform: rotateY(720deg) rotateX(90deg) translateZ(-16px) translateX(3px);
  }
  70% {
    transform: rotateY(720deg) rotateX(90deg) translateZ(-16px);
  }
  100% {
    transform: rotateY(0deg) rotateX(0deg);
  }
}

/*# sourceMappingURL=loading-animation.css.map */
