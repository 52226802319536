.create-new-table-template {
  width: 250px;
}
.create-new-table-template .create-btn {
  margin-top: 16px;
}
.create-new-table-template .type-input-wrapper {
  height: 120px;
  display: flex;
  justify-content: space-around;
}
.create-new-table-template .type-input-wrapper .example-wrapper {
  position: relative;
  flex: 2;
  cursor: pointer;
}
.create-new-table-template .type-input-wrapper .example-wrapper div.dragable-handle {
  margin-left: 50%;
  margin-top: 50%;
}
.create-new-table-template .type-input-wrapper .example-wrapper label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.create-new-table-template .type-input-wrapper .example-wrapper label.selected {
  font-weight: bold;
}
.create-new-table-template .horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-new-table-template .horizontal .side {
  flex: 4;
}
.create-new-table-template .horizontal .right.side {
  position: relative;
}
.create-new-table-template .horizontal .right.side div.dragable-handle {
  margin-left: 50%;
}

/*# sourceMappingURL=create-new-table-template.css.map */
