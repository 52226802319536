.host-venue-page {
  background: white;
  padding: 16px;
  max-width: 700px;
}
.host-venue-page p {
  line-height: 125%;
  margin-bottom: 32px;
  max-width: 500px;
}
.host-venue-page .horizontal.center {
  align-items: flex-end;
  gap: 16px;
}

/*# sourceMappingURL=host-venue-page.css.map */
