.home-page {
  position: relative;
  margin-bottom: 45px;
}
.home-page .title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-page .horizontal > * {
  margin-right: 8px;
}
.home-page .venue-list {
  cursor: pointer;
  width: 30rem;
  margin: 0 auto;
}
.home-page .venue-list h1 {
  margin-bottom: 0;
}
.home-page .venue-list .simple-button {
  margin-top: 16px;
  margin-bottom: 16px;
}
.home-page .venue-list .venue {
  display: block;
  background: white;
  color: black;
  padding: 8px 16px;
  border-left: 3px solid #fc6701;
  margin-top: 4px;
}
.home-page .venue-list .venue * {
  font-weight: 100;
  margin: 0;
}
.home-page .venue-list .venue:hover {
  background: rgb(229, 229, 229);
  transition-property: background;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  -moz-transition-timing-function: ease-in;
}
.home-page .venue-list-loading {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-page .event-list .event {
  background: white;
  padding: 4px;
  margin-bottom: 32px;
}
.home-page .event-list .event h2, .home-page .event-list .event h3 {
  margin: 0;
}
.home-page .event-list .event h3 {
  margin-bottom: 16px;
}
.home-page .event-list .event .columns {
  display: flex;
  justify-content: space-between;
}
.home-page .event-list .event .columns label {
  font-weight: bold;
}
.home-page .event-list .event .columns label, .home-page .event-list .event .columns p {
  margin: 0;
}
.home-page .event-list .event .columns div {
  padding-left: 4px;
  padding-right: 4px;
}
.home-page .event-list .event .columns div:not(:first-of-type) {
  border-left: 1px solid black;
}

/*# sourceMappingURL=home.css.map */
