.event-signup-admin-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.event-signup-admin-page .sub-page-list {
  padding: 16px;
  padding-left: 0;
}
.event-signup-admin-page .sub-page-list .simple-button {
  margin-right: 32px;
}

/*# sourceMappingURL=event-signup-admin.css.map */
