.client-event-option {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 4px;
}
.client-event-option .printer-friendly-paragraphs {
  page-break-inside: avoid;
}
.client-event-option .client-paragraph-description {
  white-space: pre-wrap;
  line-height: 125%;
  margin: 0;
}
.client-event-option .option-header {
  display: flex;
  gap: 16px;
  padding: 0 !important;
}
.client-event-option p {
  white-space: pre-wrap;
  margin: 0;
}
.client-event-option .price-tag {
  border-radius: 2px;
  border: 1px solid rgba(0, 129, 30, 0.75);
  background: #ECF6EE;
  padding: 8px;
  font-weight: 600;
  color: #00811E;
  align-self: flex-start;
}
.client-event-option .price-tag img {
  margin-right: 8px;
}

/*# sourceMappingURL=client-event-option.css.map */
