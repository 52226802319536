.dragable-handle {
  height: 24px;
  width: 24px;
  transform: translateY(-50%) translateX(-50%);
}

.dragable-handle.active {
  cursor: move;
}

.active.resize {
  cursor: col-resize;
}

/*# sourceMappingURL=dragable.css.map */
