.shared-message {
  display: flex;
  flex-direction: column;
}
.shared-message input[type=text] {
  width: 300px;
  margin-bottom: 4px;
}
.shared-message input[type=text], .shared-message textarea {
  padding: 8px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 0 1px transparent;
  outline: none;
  transition: box-shadow 0.2s, border-color 0.2s;
}
.shared-message input[type=text]:focus {
  border-color: #555;
  box-shadow: 0 0 0 2px rgba(85, 85, 85, 0.2);
}
.shared-message textarea {
  min-height: 150px;
  border: 1px solid lightgrey;
}
.shared-message h1, .shared-message h2, .shared-message h3, .shared-message h4, .shared-message h5 {
  margin: 0;
}

/*# sourceMappingURL=shared-message.css.map */
