.event-client-tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.event-client-tab .add-user-to-event-popup-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
.event-client-tab .horizontal {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
.event-client-tab .horizontal.center {
  align-items: center;
}
.event-client-tab .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.event-client-tab .economy-packages {
  width: 400px;
}
.event-client-tab .economy-packages h3 {
  margin: 0;
}
.event-client-tab .package-list {
  display: flex;
  overflow-x: auto;
  width: 100%;
  gap: 32px;
}
.event-client-tab .package-list .package {
  width: 300px;
}
.event-client-tab .selected-packages {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.event-client-tab .selected-packages .package .options {
  display: flex;
  gap: 16px;
}
.event-client-tab .selected-packages .package .options .option {
  flex: 4;
}
.event-client-tab .selected-packages .package .options .option textarea {
  width: 100%;
}
.event-client-tab .client-sections {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.event-client-tab .customer-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin: 22px 0;
}
.event-client-tab .customer-wrapper .name-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid black;
  margin-bottom: 4px;
}
.event-client-tab .customer-wrapper h2 {
  margin-bottom: 0;
}
.event-client-tab .customer-wrapper p {
  margin: 0;
}
.event-client-tab .event-note {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}
.event-client-tab .event-note h3 {
  margin-bottom: 0;
}
.event-client-tab .event-note textarea {
  min-height: 150px;
}
.event-client-tab .event-note .simple-button {
  width: 100px;
  margin-top: 8px;
}
.event-client-tab .event-note .simple-button.disabled {
  display: none;
}

/*# sourceMappingURL=event-client-tab.css.map */
