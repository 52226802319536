.tab-container {
  width: 100%;
  display: flex;
  gap: 8px;
  position: relative;
  background: white;
  overflow-x: auto;
}
.tab-container .tab {
  padding: 16px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.tab-container .tab:hover {
  text-decoration: underline;
}
.tab-container .tab.selected.parent ::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: gray;
}
.tab-container.submenu .tab {
  padding: 4px 16px;
  margin-bottom: 8px;
}
.tab-container .selected {
  border-bottom: 2px solid black;
}

@media print {
  .tab-container {
    display: none;
  }
}

/*# sourceMappingURL=tabs.css.map */
