.host-event-admin-page {
  background: #fff;
  padding: 16px;
}
.host-event-admin-page .header p {
  margin: 0;
}
.host-event-admin-page table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 20px;
}
.host-event-admin-page table th {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.host-event-admin-page table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.host-event-admin-page table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.host-event-admin-page table tr:hover {
  background-color: #f5f5f5;
}
.host-event-admin-page img.rotate {
  transform: rotate(180deg);
}
.host-event-admin-page .checkin-popup {
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  top: 120px;
}
.host-event-admin-page .checkin-popup h2 {
  text-align: center;
}
.host-event-admin-page .checkin-popup .count-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 16px;
}
.host-event-admin-page .checkin-popup .count-wrapper label {
  margin: 0;
}
.host-event-admin-page .checkin-popup .count-wrapper .count-label {
  font-size: 1.8em;
  font-weight: bold;
  padding-right: 4px;
}
.host-event-admin-page .checkin-popup .prev-checkins {
  width: 100%;
  min-height: 120px;
}
.host-event-admin-page .checkin-popup .prev-checkins p {
  margin: 0;
}
.host-event-admin-page .checkin-popup table {
  width: 100%;
}
.host-event-admin-page .checkin-popup table td, .host-event-admin-page .checkin-popup table th {
  text-align: left;
}
.host-event-admin-page .checkin-popup table .delete-td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.host-event-admin-page .checkin-popup table .delete-td button {
  margin-top: 0;
}
.host-event-admin-page .checkin-popup .simple-button {
  margin-top: 45px;
}

/*# sourceMappingURL=host-event-admin-page.css.map */
