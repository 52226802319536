.room-page {
  width: 100%;
  height: 100%;
  background: #DAD7D7;
}
.room-page main {
  display: flex;
}
.room-page .top-bar {
  padding: 8px 32px;
  width: calc(100% - 64px);
  background: #282828;
  color: white !important;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}
.room-page .top-bar a {
  color: white;
}
.room-page .top-bar h4 {
  padding: 0;
  margin: 0;
}
.room-page .top-bar .back-btn {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.room-page .top-bar .back-btn img {
  width: 24px;
  transform: rotate(90deg);
}
.room-page .top-bar .simple-button {
  padding: 2px 12px;
}
.room-page .top-bar .scale-label {
  text-decoration: underline;
  font-weight: normal;
  cursor: pointer;
}
.room-page .room-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}
.room-page .measuring-tool-input-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 40%;
  width: 700px;
  padding: 16px;
  background: white;
  border: 1px solid black;
  box-shadow: 0 3rem 4.5rem 0 rgba(0, 0, 0, 0.35), 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.25);
}
.room-page .display-which-room-type-wrapper {
  position: absolute;
  background: white;
  box-shadow: 0 1rem 2.5rem 0 rgba(0, 0, 0, 0.35), 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.25);
  padding: 16px 32px;
  top: 20%;
  left: calc(50% + 150px);
  transform: translateX(-50%);
}
.room-page .left-static-menu, .room-page .right-static-menu {
  background: white;
  padding: 8px;
  min-width: 260px;
  box-shadow: 0 1rem 2.5rem 0 rgba(0, 0, 0, 0.35), 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.25);
  z-index: 2;
}
.room-page .right-static-menu {
  width: 260px;
  height: calc(100vh - 52px);
  border-left: 1px solid whitesmoke;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.room-page .right-static-menu .grower {
  flex-grow: 1;
}
.room-page .right-static-menu .bottom-controls {
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 260px;
  border-top: 1px solid grey;
  background: white;
}
.room-page .right-static-menu .bottom-controls p {
  margin: 0;
  margin-top: 4px;
}
.room-page .right-static-menu .bottom-controls .horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 8px;
  height: 64px;
}
.room-page .right-static-menu .bottom-controls .horizontal .undo-wrapper, .room-page .right-static-menu .bottom-controls .horizontal .zoom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.room-page .right-static-menu .bottom-controls .horizontal .undo-wrapper img {
  background: whitesmoke;
  padding: 4px;
  border-radius: 4px;
}

/*# sourceMappingURL=room-page.css.map */
