.multiple-choice {
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
}
.multiple-choice label {
  position: relative;
  border: 1px solid black;
  padding: 16px 32px;
  margin-left: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  text-align: center;
}
.multiple-choice label img {
  position: absolute;
  border-radius: 100%;
  width: 14px;
  top: 4px;
  right: 4px;
}
.multiple-choice label img:hover {
  box-shadow: 0 0 6px grey;
}
.multiple-choice label:hover {
  transition: background-color 0.2s;
  background: rgba(55, 169, 135, 0.6);
  border: 1px solid transparent;
}
.multiple-choice label:hover img {
  background: #004699;
}
.multiple-choice label.selected {
  border: 1px solid transparent;
  background: rgba(55, 169, 135, 0.9);
  color: white;
}

/*# sourceMappingURL=multiple-choice.css.map */
