.link-button {
  position: relative;
  font-weight: 600;
  border-radius: 4px;
  text-decoration: none;
  background: var(--button-color);
  color: white;
  padding: 16px 80px 16px 20px;
  font-size: 18px;
}
.link-button img {
  position: absolute;
  height: 0.5rem;
  right: 16px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}
.link-button:hover {
  background: var(--button-hover-color);
}

/*# sourceMappingURL=link-button.css.map */
