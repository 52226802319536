.venue-page {
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.venue-page section {
  background: white;
  padding: 10px;
  border-radius: 8px;
}
.venue-page section:not(:first-of-type) {
  margin-top: 16px;
}
.venue-page section:not(:last-of-type) {
  margin-bottom: 16px;
}
.venue-page section .name-wrapper h1, .venue-page section .name-wrapper h5 {
  margin: 0;
}
.venue-page section .buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.venue-page section .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.venue-page section .header h1 {
  margin: 0;
}
.venue-page section .header p {
  margin: 0;
  margin-bottom: 32px;
}
.venue-page .event-tab {
  padding-top: 2em;
}
.venue-page .event-tab h2 {
  margin: 0;
}
.venue-page .event-tab .table-wrapper {
  overflow-y: auto;
  max-height: 400px;
  width: 800px;
  margin-bottom: 64px;
}
.venue-page .event-tab .table-wrapper .event-table {
  width: calc(100% - 350px);
  border-collapse: collapse;
  text-align: left;
  height: auto;
  table-layout: fixed;
}
.venue-page .event-tab .table-wrapper .event-table th {
  position: sticky;
  top: 0;
}
.venue-page .event-tab .table-wrapper .event-table tbody {
  overflow-y: scroll;
}
.venue-page .event-tab .table-wrapper .event-table tbody td {
  padding: 8px 4px;
}
.venue-page .event-tab .table-wrapper .event-table tbody tr:hover {
  background: white;
}
.venue-page section .table-blur-wrapper {
  position: relative;
}
.venue-page section .table-blur-wrapper .tables {
  display: flex;
  gap: 32px;
  width: 100%;
  overflow-x: auto;
  align-items: flex-end;
}
.venue-page section .table-blur-wrapper .tables .table-wrapper {
  position: relative;
  width: 250px;
  background: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.venue-page section .table-blur-wrapper .tables .table-wrapper .simple-button {
  width: 100%;
}
.venue-page section .table-blur-wrapper .tables .table-wrapper h2 {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 0;
}
.venue-page section .table-blur-wrapper .tables .table-wrapper:last-of-type {
  padding-right: 32px;
}
.venue-page section .table-blur-wrapper .tables .table-wrapper .table-model-wrapper {
  position: relative;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.venue-page section .table-blur-wrapper .tables .table-wrapper .table-model-wrapper .positional-wrap {
  pointer-events: none;
  position: relative;
}
.venue-page section .table-blur-wrapper .tables .table-wrapper .table-model-wrapper .event-killer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: all;
}
.venue-page section .table-blur-wrapper .right-side-blur {
  position: absolute;
  top: 0;
  left: calc(100% - 32px);
  width: 32px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  backdrop-filter: blur(1.4px);
}
.venue-page .rooms {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.venue-page .rooms .room-list-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.venue-page .rooms .room-list-item h3, .venue-page .rooms .room-list-item p {
  margin: 0;
}
.venue-page .rooms .room-list-item .room-img-wrapper {
  height: 200px;
  width: 200px;
}
.venue-page .rooms .room-list-item img {
  width: 100%;
}

/*# sourceMappingURL=venue-page.css.map */
