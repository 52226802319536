.user-admin-page table {
  width: 100%;
}
.user-admin-page table tr, .user-admin-page table th {
  text-align: left;
}
.user-admin-page table tbody tr {
  cursor: pointer;
}
.user-admin-page table tbody tr:hover {
  background-color: #f5f5f5;
}

/*# sourceMappingURL=admin-user.css.map */
