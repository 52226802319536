.economy-table-wrapper {
  width: calc(100% - 64px);
  margin: 0 auto;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.2), 0px 2px 4px -2px rgba(0, 0, 0, 0.15), 0px 4px 10px -4px rgba(0, 0, 0, 0.15);
  background: white;
  /* Shadow */
}
.economy-table-wrapper .economy-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.economy-table-wrapper .economy-table thead th {
  text-decoration: underline;
}
.economy-table-wrapper .economy-table td:not(:first-child), .economy-table-wrapper .economy-table th:not(:first-child) {
  text-align: right;
}
.economy-table-wrapper .economy-table tbody td, .economy-table-wrapper .economy-table tbody th {
  padding: 8px 0;
}
.economy-table-wrapper .economy-table tbody tr:nth-last-child(2) td {
  padding-bottom: 16px;
}
.economy-table-wrapper .economy-table tr.total th, .economy-table-wrapper .economy-table tr.total td {
  padding-top: 16px;
  border-top: 1px solid black;
}

/*# sourceMappingURL=economy-table.css.map */
