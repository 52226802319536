.sektion {
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
}
.sektion .printer-friendly-paragraphs {
  display: none;
  page-break-inside: avoid;
}
.sektion .horizontal {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
}
.sektion .grower {
  flex-grow: 1;
}
.sektion input[type=text] {
  min-width: 300px;
  flex-grow: 1;
}
.sektion input[type=text], .sektion textarea {
  padding: 8px 12px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 0 1px transparent;
  outline: none;
  transition: box-shadow 0.2s, border-color 0.2s;
}
.sektion input[type=text]:focus {
  border-color: #555;
  box-shadow: 0 0 0 2px rgba(85, 85, 85, 0.2);
}
.sektion textarea {
  min-height: 150px;
  border: 1px solid lightgrey;
  margin-bottom: 4px;
}
.sektion h1, .sektion h2, .sektion h3, .sektion h4, .sektion h5 {
  margin: 0;
}

@media print {
  .sektion .horizontal {
    align-items: center;
  }
  .sektion input[type=text], .sektion textarea {
    border: 1px solid transparent;
    box-shadow: none;
  }
  .sektion textarea {
    display: none !important;
  }
  .sektion .printer-friendly-paragraphs {
    display: block;
    padding: 0;
    margin: 0;
  }
}

/*# sourceMappingURL=sektion.css.map */
