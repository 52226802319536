.client-proposal-page {
  height: calc(100% - 75px);
  overflow-y: auto;
}
.client-proposal-page main {
  width: calc(100% - 48px);
  max-width: 920px;
  margin: 32px auto;
  border-radius: 8px;
  background: #FFF;
  padding: 24px;
  padding-bottom: 32px;
}
.client-proposal-page main .sections {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.client-proposal-page main .document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.client-proposal-page main .document-header .value-key-label {
  flex-direction: column;
}
.client-proposal-page main .document-intro-image-wrapper {
  display: flex;
  justify-content: center;
  max-width: 250px;
}
.client-proposal-page main .document-intro-image-wrapper .document-intro-image {
  max-height: 250px;
  max-width: 100%;
}
.client-proposal-page h1.proposal-client-h1 {
  display: flex;
  margin-top: 0;
  align-items: center;
}
.client-proposal-page h1.proposal-client-h1 h1 {
  font-size: 20px;
}
.client-proposal-page h1.proposal-client-h1 img {
  margin-right: 10px;
  height: 28px;
}
.client-proposal-page .accept-tools {
  position: fixed;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px;
  width: calc(100% - 32px);
}
.client-proposal-page .accept-tools.accepted {
  background: #31c850;
}
.client-proposal-page .accept-tools .accepted-label {
  color: white;
  font-weight: bold;
}
.client-proposal-page .final-accept-popup {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
}
.client-proposal-page .final-accept-popup iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.client-proposal-page .final-accept-popup .terms-and-conditions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 1.2em;
  font-weight: 500;
  padding: 16px 0;
}
.client-proposal-page .final-accept-popup .terms-and-conditions .terms-and-condition-content {
  font-size: 1rem;
}

/*# sourceMappingURL=client-proposal-page.css.map */
