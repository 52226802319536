.element-menu {
  background: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.element-menu .element-menu-wrapper {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}
.element-menu .element-menu-wrapper:hover {
  background: #f5f5f5;
}

/*# sourceMappingURL=element-menu.css.map */
