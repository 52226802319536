.special-search-icon {
  padding: 3px 16px;
  margin: 4px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.special-search-icon .icon {
  margin-right: 4px;
}
.special-search-icon .icon, .special-search-icon .text {
  pointer-events: none;
}

.special-search-icon:hover {
  background: #f1f4ff;
  transition: 200ms background-color;
}

.special-search-icon.active {
  border: 1px solid #4285f4;
}

/*# sourceMappingURL=special-search-icon.css.map */
