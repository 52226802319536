body {
  overflow: hidden;
}

.navigatable {
  width: 100%;
  height: 100%;
}
.navigatable.mouse-grab {
  cursor: grab;
}
.navigatable.panning {
  cursor: grabbing;
}
.navigatable .mouse-grabbing {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#container {
  height: 100%;
  width: 100%;
  position: absolute;
}

.area {
  border: 1px dashed blue;
  height: 100%;
  width: 100%;
  position: absolute;
}

.circle {
  height: 200px;
  width: 200px;
  background-color: navajowhite;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.rectangle {
  background-color: navajowhite;
  height: 150px;
  width: 250px;
  position: relative;
}

.text-area {
  float: right;
  position: relative;
}

/*# sourceMappingURL=navigatable.css.map */
