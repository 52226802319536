.room-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
}
.room-popup h3 {
  margin: 0;
}
.room-popup .input-label {
  margin-left: 2px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}
.room-popup .input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.room-popup .horizontal {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0;
}
.room-popup .horizontal span {
  width: calc(100% - 32px);
  opacity: 60%;
}
.room-popup img {
  max-width: 400px;
}
.room-popup .example-wrapper {
  width: 600px;
  height: 600px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  border: 1px dashed black;
}
.room-popup .example-wrapper .example {
  position: relative;
  border: 1px solid black;
  background: #4192ab;
}
.room-popup .example-wrapper .example .marker {
  position: absolute;
  margin: 0;
  font-size: 1.2em;
  color: white;
  text-align: center;
}
.room-popup .example-wrapper .example .marker.width {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.room-popup .example-wrapper .example .marker.height {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/*# sourceMappingURL=create-room.css.map */
