.seating-table.Round {
  border-radius: 100%;
}
.seating-table.Round .table-name {
  position: absolute;
  transform: translateX(-50%) translateY(calc(-110% - 25px));
  font-weight: bold;
  background: white;
  padding: 1px;
  margin-left: 1px;
}

.seating-table.Sofa {
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
}
.seating-table.Sofa .table-name {
  transform: translateX(-50%) translateY(80%);
}

.seating-table {
  position: absolute;
  border: 1px solid black;
  height: 50px;
  width: 150px;
  background: white;
  text-align: center;
  cursor: pointer;
}
.seating-table label {
  pointer-events: none;
}
.seating-table .table-name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  font-weight: bold;
  pointer-events: none;
  background: white;
  padding: 1px;
  margin-left: 1px;
}
.seating-table .seats-left-label {
  position: absolute;
  margin: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  pointer-events: none;
}
.seating-table .seats {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 0;
  height: 100%;
}
.seating-table .chair {
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1.5px 2px 0 rgba(0, 0, 0, 0.2);
}

.seating-table.hovering {
  border-width: 2px;
}

.seating-table.hovering-link {
  background: green;
}

.seating-table.hovering-none {
  background: red !important;
}
.seating-table.hovering-none .seats-left-label {
  font-weight: bold;
}

.seating-table.being-placed {
  background: blue;
}

.seating-table.none-seated {
  background: green;
}

.seating-table.half-seated {
  background: orange;
}

.seating-table.completely-seated {
  background: red;
}

/*# sourceMappingURL=seating-table.css.map */
