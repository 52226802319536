.host-client-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
}
.host-client-page main {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  max-width: 920px;
  padding: 0 20px;
  background-color: #fff;
  border-radius: 10px;
  height: 100vh;
  overflow-y: hidden;
}
.host-client-page main .checkin-header {
  width: 100%;
  background-color: #fff;
}
.host-client-page main .checkin-header .overall-checkin-count-wrapper {
  display: flex;
}
.host-client-page main .checkin-header .overall-checkin-count-wrapper .overall-checkin-count:first-of-type {
  font-weight: bold;
  border-bottom: 2px solid black;
}
.host-client-page main .table-scrollable {
  overflow-y: auto;
}
.host-client-page main table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  height: auto;
  margin-bottom: 200px;
}
.host-client-page main table th {
  right-margin: 16px;
}
.host-client-page main table tbody {
  overflow-y: scroll;
}
.host-client-page main table tbody tr {
  height: 55px;
  cursor: pointer;
}
.host-client-page main table tbody tr td:first-of-type {
  min-width: 60px;
}
.host-client-page main table tbody tr td:nth-of-type(2), .host-client-page main table tbody tr td:nth-of-type(3) {
  min-width: 150px;
}
.host-client-page main table tbody tr:nth-child(odd) {
  background: #f5f5f5;
}
.host-client-page main table tbody tr:hover {
  background: white;
}
.host-client-page .guest-seating {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.host-client-page .guest-seating .table-color {
  margin-left: 4px;
  height: 16px;
  width: 16px;
}
.host-client-page .logout-button {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: auto;
}
.host-client-page .guest-search-bar input {
  font-size: 1.2em !important;
  padding: 12px;
  margin-bottom: 8px;
}
.host-client-page .checkin-popup {
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  top: 120px;
}
.host-client-page .checkin-popup h2 {
  text-align: center;
}
.host-client-page .checkin-popup .count-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 16px;
}
.host-client-page .checkin-popup .count-wrapper label {
  margin: 0;
}
.host-client-page .checkin-popup .count-wrapper .count-label {
  font-size: 1.8em;
  font-weight: bold;
  padding-right: 4px;
}
.host-client-page .checkin-popup .prev-checkins {
  width: 100%;
  min-height: 120px;
}
.host-client-page .checkin-popup .prev-checkins p {
  margin: 0;
}
.host-client-page .checkin-popup table {
  width: 100%;
}
.host-client-page .checkin-popup table td, .host-client-page .checkin-popup table th {
  text-align: left;
}
.host-client-page .checkin-popup .simple-button {
  margin-top: 45px;
}
.host-client-page .footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  box-shadow: 0 -6px 8px rgba(0, 0, 0, 0.2);
}

/*# sourceMappingURL=host-client-page.css.map */
