.simple-input {
  position: relative;
  display: flex;
  flex-direction: column;
}
.simple-input label {
  margin: 0 0 8px;
  font-weight: bold;
}
.simple-input input {
  padding: 8px 14px;
  border: 1px solid grey;
}
.simple-input textarea {
  min-width: 300px;
  min-height: 100px;
}
.simple-input .non-selectable {
  cursor: pointer;
}
.simple-input .input-wrapper:hover:not(.disabled) .input-field-icon-wrapper .input-field-icon {
  opacity: 1;
  background: darkblue;
  cursor: pointer;
}
.simple-input.with-options input {
  border-radius: 8px;
  padding: 12px 16px;
}
.simple-input .simple-input-dropdown {
  position: absolute;
  top: 100%;
  z-index: 2;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.simple-input .simple-input-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
}
.simple-input .simple-input-dropdown li:hover {
  background-color: #f5f5f5;
}
.simple-input .simple-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}
.simple-input .simple-input-wrapper input {
  width: 100%;
}
.simple-input .simple-input-wrapper .input-field-icon {
  background: black;
  opacity: 0.6;
  height: 24px;
  width: 24px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  border-radius: 100%;
}
.simple-input .input-wrapper:hover .input-field-wrapper {
  border: 1px solid #7ca2f8;
}
.simple-input .error {
  color: red;
}

/*# sourceMappingURL=simple-input.css.map */
