.alignment-controls p {
  width: 100%;
}
.alignment-controls h5 {
  margin-bottom: 0;
}
.alignment-controls .videos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 120px;
}
.alignment-controls .videos img {
  width: 40px;
  cursor: pointer;
}
.alignment-controls .videos img:hover {
  background: #004aad;
}
.alignment-controls .alignment-tooltip {
  width: 200px;
  height: 340px;
  overflow-y: hidden;
}
.alignment-controls .alignment-tooltip p {
  padding: 0;
  margin: 0;
  margin-top: 12px;
}
.alignment-controls .alignment-tooltip .video-wrapper {
  pointer-events: none;
}
.alignment-controls .alignment-tooltip .video-wrapper video {
  border-radius: 0 0 8px 8px;
  opacity: 0;
  width: 100%;
}
.alignment-controls .alignment-tooltip .video-wrapper .video-tooltip {
  opacity: 100%;
}

/*# sourceMappingURL=alignment-controls.css.map */
