.program-wrapper p {
  max-width: 200px;
}
.program-wrapper h4 {
  margin: 0;
  font-weight: bold;
}
.program-wrapper .item-list {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.program-wrapper .item-list .program-item {
  display: flex;
  gap: 1em;
}
.program-wrapper .item-list .program-item .item-time-selection-wrapper {
  flex: 4;
  display: flex;
  align-items: center;
  gap: 1em;
}
.program-wrapper .item-list .program-item .program-item-text {
  flex: 8;
  border: 1px solid lightgray;
}
.program-wrapper .item-list .delete-icon {
  padding: 2px;
  cursor: pointer;
  width: 17px;
}

/*# sourceMappingURL=itinerary-timeplan.css.map */
