.simple-card {
    width: 260px;
    height: 95px;
    padding: 24px;
    background: white;
    box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 0.5px solid #E0E0E0;
    gap: 4px;

    .simple-card-title {
        font-size: 14px;
        font-family: 'League Spartan', sans-serif;
        font-weight: 300;
        word-wrap: break-word;
    }

    .simple-card-text {
        font-size: 20px;
        font-weight: 400;
        word-wrap: break-word;
    }
}
