.event-proposal-page .horizontal {
  gap: 16px;
}
.event-proposal-page .document-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.event-proposal-page .document {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 280px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.2), 0px 2px 4px -2px rgba(0, 0, 0, 0.15), 0px 4px 10px -4px rgba(0, 0, 0, 0.15);
  width: 200px;
  gap: 14px;
  align-self: stretch;
}
.event-proposal-page .document .proposal-status {
  background: #eee;
  font-weight: 600;
  font-size: 0.9em;
  padding: 4px;
  display: flex;
}
.event-proposal-page .document .proposal-status img {
  margin-right: 4px;
}
.event-proposal-page .document .proposal-status.godkendt {
  background: var(--primary-color4);
  color: white;
}
.event-proposal-page .document.create-new {
  border: 1px dashed black;
  background: rgba(238, 238, 238, 0.2666666667);
}
.event-proposal-page .document.create-new h2 {
  background: #eee;
  height: 22px;
  width: 100%;
}
.event-proposal-page .document.create-new:hover {
  background: rgba(255, 255, 255, 0.5333333333);
}
.event-proposal-page .document .client-state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.event-proposal-page .document h2 {
  margin: 0;
}
.event-proposal-page .document hr {
  width: 100%;
}
.event-proposal-page .document .simple-button {
  align-self: stretch;
}
.event-proposal-page .document h3.locked {
  background: var(--button-hover-color-red);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
}
.event-proposal-page .document img.lock-status {
  width: 20px;
}
.event-proposal-page .document .status {
  padding: 4px 16px;
  border-radius: 16px;
  color: black;
  font-weight: 600;
  font-size: 0.8em;
}
.event-proposal-page .document .status.kladde {
  background: var(--primary-color1);
}
.event-proposal-page .document .status.afsendt {
  background: var(--primary-color5);
}
.event-proposal-page .document .status.godkendt {
  background: var(--primary-color4);
}
.event-proposal-page .document .status.afvist {
  background: var(--button-color-red);
}
.event-proposal-page .add-new-document-popup {
  width: 90%;
  top: 120px;
}

/*# sourceMappingURL=event-proposal-page.css.map */
