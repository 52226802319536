.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1vh;
  width: calc(100% - 2vh);
  gap: 10rem;
  color: black;
  overflow-y: auto;
  height: 100vh;
}
.landing-page .header {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landing-page .title {
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  margin-bottom: 0;
}
.landing-page .title span {
  color: #004699;
}
.landing-page p {
  font-size: 1.2em;
}
.landing-page section {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  position: relative;
}
.landing-page section div {
  flex: 1;
  max-width: 600px;
  margin: 0 auto;
}
.landing-page section .title {
  font-size: 6.5vh;
  max-width: 600px;
}
.landing-page section h1 {
  font-size: 4vh;
  font-family: "Inter", sans-serif;
}
.landing-page section h1, .landing-page section h2 {
  font-size: 3.5vh;
  font-family: "Inter", sans-serif;
}
.landing-page section h1 {
  margin-bottom: 0;
}
.landing-page section h3 {
  font-family: "Inter", sans-serif;
  font-size: 2vh;
}
.landing-page section p {
  line-height: 1.8rem;
  margin-bottom: 32px;
  max-width: 600px;
}
.landing-page section .section-header {
  position: absolute;
  top: -40px;
  left: 0;
  font-size: 1.2rem;
  color: #7E64ED;
  font-weight: bold;
}
.landing-page section img {
  max-width: 100%;
  margin: 0 auto;
}
.landing-page section.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.landing-page .flow-chart {
  display: flex;
  flex-direction: column;
}
.landing-page .flow-chart div {
  min-width: 350px;
}
.landing-page .flow-chart .text-wrapper {
  text-align: center;
}
.landing-page section.who-we-are {
  flex-direction: column;
  width: 100%;
}
.landing-page section.who-we-are .horizontal {
  flex-wrap: wrap;
  max-width: 800px;
  gap: 2rem;
}
.landing-page section.who-we-are .horizontal p {
  min-width: 300px;
}
.landing-page section.contact {
  justify-content: space-evenly;
}
.landing-page section.contact div {
  min-width: 200px;
}
.landing-page section.contact form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 350px;
}
.landing-page section.contact form input, .landing-page section.contact form textarea {
  padding: 1rem;
  border: 1px solid #7E64ED;
  border-radius: 5px;
}
.landing-page section.contact form .simple-button {
  width: 150px;
}
.landing-page .horizontal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  justify-items: center;
}
.landing-page .horizontal div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  min-width: 300px;
}
.landing-page .horizontal div .small-divider {
  width: 50px;
  height: 2px;
  background-color: #7E64ED;
  margin: 0 0;
  margin-bottom: 1rem;
}
.landing-page .testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.landing-page .testimonial h3 {
  font-size: 2.5vh;
  margin-bottom: 0;
}
.landing-page .testimonial h2 {
  margin-top: 0;
  border-bottom: 6px solid #7E64ED;
}

/*# sourceMappingURL=landing-page.css.map */
