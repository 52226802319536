.login-page {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 620px) {
  .login-page {
    flex-direction: column;
    align-content: center;
  }
}
.login-page .left-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
  flex-shrink: 1;
  background: white;
}
@media only screen and (min-width: 620px) {
  .login-page .left-menu {
    width: 100%;
  }
}
.login-page .left-menu h1 {
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 5em;
  font-weight: bold;
}
.login-page .left-menu h1 span {
  color: #004aad;
}
.login-page .left-menu img {
  width: calc(100% - 32px);
}
.login-page .left-menu .wrapper {
  background: white;
  padding: 32px 32px;
  border-radius: 4px;
}
.login-page .right-menu {
  flex: 4;
  flex-grow: 2;
  display: flex;
  background: linear-gradient(to right, white, #d7d7d7);
}
@media only screen and (max-width: 620px) {
  .login-page .right-menu {
    width: 100%;
    justify-content: center;
    background: linear-gradient(to bottom, white, #d7d7d7);
  }
}
.login-page .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-page .wrapper h1 {
  margin-bottom: 16px;
}
.login-page .wrapper h2 {
  margin-top: 0;
  margin-bottom: 40px;
}
.login-page .wrapper input {
  font-size: 1.3em;
}
.login-page .wrapper .simple-button {
  background: #073350;
  background: linear-gradient(to left, #073350, #073370);
}
.login-page .wrapper .input-field, .login-page .wrapper .simple-button, .login-page .wrapper .loading-animation {
  width: 300px;
  margin: 4px 16px;
}
.login-page .wrapper label.remember-me {
  margin: 4px 16px;
}
.login-page .wrapper .simple-button {
  padding: 0;
  height: 55px;
  margin-top: 16px;
}

/*# sourceMappingURL=login.css.map */
