.home-page-menu-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.home-page-menu-wrapper .left-menu {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 16px;
  border-right: 1px solid lightgrey;
}
.home-page-menu-wrapper .left-menu a {
  min-width: 200px;
  color: black;
  font-size: 1.2em;
  padding: 16px 0;
  text-decoration: none;
}
.home-page-menu-wrapper .left-menu a:hover {
  text-decoration: underline;
}
.home-page-menu-wrapper .left-menu a.selected {
  text-decoration: underline;
}
.home-page-menu-wrapper .left-menu .event-title {
  font-weight: bold;
}
.home-page-menu-wrapper .left-menu .grower {
  flex-grow: 1;
}
.home-page-menu-wrapper .scrollable {
  height: 95vh;
  max-width: 1400px;
  overflow-y: auto;
  margin-left: 2px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5vh;
  flex-grow: 1;
  margin: 0 auto;
}

/*# sourceMappingURL=venue-client-menu-wrapper.css.map */
