.seating-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ccc;
}
.seating-page .menu-wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  height: 100vh;
  min-width: 400px;
  z-index: 2;
}
.seating-page .menu-wrapper .overview {
  height: 120px;
}
.seating-page .menu-wrapper .seating-guest-list {
  flex-grow: 1;
}
.seating-page .menu-wrapper .seating-guest-list .table-wrapper {
  height: auto;
}
.seating-page .menu-wrapper .seating-guest-list .table-wrapper .power-table {
  height: calc(-227px + 100vh) !important;
}
.seating-page .seating-locked-popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(120, 120, 120, 0.8);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.seating-page .seating-locked-popup h1, .seating-page .seating-locked-popup p, .seating-page .seating-locked-popup > * {
  width: 360px;
  text-align: center;
  margin: 0;
  background: white;
  padding: 32px 64px;
}
.seating-page .seating-locked-popup h1, .seating-page .seating-locked-popup p {
  padding-bottom: 0;
}

/*# sourceMappingURL=seating.css.map */
