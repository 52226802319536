.event-schedule-page {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.event-schedule-page .horizontal {
  display: flex;
  gap: 1em;
  width: 100%;
}
.event-schedule-page .horizontal .grower {
  flex-grow: 1;
}
.event-schedule-page table td, .event-schedule-page table th {
  text-align: left;
}
.event-schedule-page .sections {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

@media print {
  .event-schedule-page .add-program-item {
    display: none;
  }
  .event-schedule-page .sektion-intro, .event-schedule-page .timeplan-intro {
    display: none;
  }
}

/*# sourceMappingURL=event-schedule-page.css.map */
