.room-list {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  overflow-x: auto;
}
.room-list .room-list-item {
  display: flex;
  align-items: center;
}
.room-list img {
  max-width: 150px;
}

/*# sourceMappingURL=room-list.css.map */
