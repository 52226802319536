.venue-client-seating-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.venue-client-seating-page .grower {
  flex-grow: 1;
}
.venue-client-seating-page .goto-seating-btn {
  margin-bottom: 20px;
  width: 350px;
}
.venue-client-seating-page .tip-wrapper {
  width: 100%;
  margin-bottom: 45px;
}
.venue-client-seating-page .tip-wrapper .tip {
  margin: 32px 0;
}
.venue-client-seating-page .tip-wrapper .tip h2, .venue-client-seating-page .tip-wrapper .tip h3 {
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.venue-client-seating-page .tip-wrapper .tip p {
  margin: 0;
  padding-left: 16px;
}
.venue-client-seating-page .vertical {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.venue-client-seating-page .seating-overview table {
  text-align: left;
}
.venue-client-seating-page .seating-overview table td {
  padding-left: 4px;
}

/*# sourceMappingURL=venue-client-seating-page.css.map */
