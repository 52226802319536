.event-page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 8px;
}
.event-page-wrapper .header {
  border-radius: 8px 8px 0 0;
}
.event-page-wrapper .header h1, .event-page-wrapper .header h5 {
  margin: 0;
}
.event-page-wrapper section {
  background: white;
  padding: 10px;
  border-radius: 8px;
}
.event-page-wrapper section :first-child {
  margin-top: 0;
}
.event-page-wrapper .bk-btn {
  align-self: flex-start;
}

@media print {
  .event-page-wrapper {
    padding: 0;
  }
}

/*# sourceMappingURL=event-page-wrapper.css.map */
