.guest-home {
  font-size: 1.1em;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.guest-home .grower {
  flex-grow: 1;
}
.guest-home p, .guest-home h3 {
  margin-bottom: 0;
  max-width: 100%;
}
.guest-home ol {
  margin-top: 0;
}
.guest-home .note-to-client {
  font-family: "Arima Madurai", "Raleway", "sans-serif";
}
.guest-home .seating-link-wrapper {
  padding-bottom: 20px;
}
.guest-home .vertical {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.guest-home .event-selector {
  display: flex;
  justify-content: space-around;
}
.guest-home .event-selector .simple-button {
  margin-top: 16px;
  margin-bottom: 16px;
}
.guest-home .visual-guide {
  display: flex;
  justify-content: space-around;
  margin: 45px 0;
}
.guest-home .visual-guide .guide-step {
  position: relative;
  height: 250px;
  width: 150px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 1em;
}
.guest-home .visual-guide .guide-step .background-number {
  position: absolute;
  font-size: 9em;
  color: rgba(255, 0, 0, 0.1);
}
.guest-home .visual-guide .guide-step img {
  position: absolute;
  background: green;
  bottom: 16px;
  height: 48px;
  width: 48px;
  border-radius: 100%;
}
.guest-home .visual-guide .guide-step a {
  position: absolute;
  z-index: 5;
  bottom: 16px;
}
.guest-home .visual-guide .guide-step.success .background-number {
  color: rgb(0, 255, 0);
}

/*# sourceMappingURL=guest-home.css.map */
