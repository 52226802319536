.table-edit-window {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.table-edit-window .table-name-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}
.table-edit-window .table-name-wrapper .delete-wrapper {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  padding: 6px;
}
.table-edit-window .table-name-wrapper .delete-wrapper:hover {
  background: #ffb268;
}
.table-edit-window .table-name-wrapper .delete-wrapper img {
  width: 100%;
  height: 100%;
}
.table-edit-window .rotation-handle:nth-of-type(2) {
  margin-bottom: 36px;
}

/*# sourceMappingURL=table-edit-window.css.map */
