.event-tab {
  display: flex;
  flex-direction: column;
  gap: 3em;
  width: 100%;
}
.event-tab .layout-selection-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.event-tab .layout-selection-wrapper .grower {
  flex-grow: 1;
}
.event-tab .layout-selection-wrapper .layout-selection {
  display: flex;
  gap: 8px;
  height: 1em;
  width: 180px;
  cursor: pointer;
  border: 1px solid lightgrey;
  padding: 8px 16px;
}
.event-tab .layout-selection-wrapper .layout-selection img {
  height: 100%;
}
.event-tab .layout-selection-wrapper input[type=button] {
  padding: 9px 22px;
}
.event-tab .event-list-wrapper {
  width: 100%;
}
.event-tab .event-list-wrapper .event-filter-wrapper {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
}
.event-tab .event-list-wrapper .event-filter-wrapper .grower {
  flex-grow: 1;
}
.event-tab .event-list-wrapper .event-table {
  position: relative;
  border: 1px solid lightgray;
  border-collapse: collapse;
}
.event-tab .event-list-wrapper .event-table td, .event-tab .event-list-wrapper .event-table th {
  text-align: left;
  padding: 8px 16px;
}
.event-tab .event-list-wrapper .event-table thead tr > th {
  border-bottom: 1px solid lightgray;
}
.event-tab .event-list-wrapper .event-table td a {
  color: black;
  text-decoration: none;
}
.event-tab .event-list-wrapper .event-table tbody > tr {
  cursor: pointer;
  position: relative;
}
.event-tab .event-list-wrapper .event-table tbody > tr:not(:last-of-type) {
  border-bottom: 2px solid transparent;
}
.event-tab .event-list-wrapper .event-table tbody > tr:hover {
  margin-bottom: 0;
  border-bottom: 2px solid #2a42b7;
}
.event-tab .event-list-wrapper .event-table tbody > tr .hover-info {
  position: absolute;
  width: 250px;
  background: white;
  border: 1px solid lightgrey;
  padding: 16px;
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.event-tab .event-list-wrapper .event-table tbody > tr .hover-info p {
  margin: 0;
}
.event-tab .event-list-wrapper .event-table tbody > tr:hover .hover-info {
  display: block;
}
.event-tab .sale-status-td {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.event-tab .sale-status-td .sale-status {
  font-size: 0.7em;
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
  padding: 1px 8px;
  width: auto;
}
.event-tab .sale-status-td .sale-status.won {
  background: #618B4C;
  color: white;
}
.event-tab .sale-status-td .sale-status.lost {
  background: #B34A4A;
  color: white;
}
.event-tab .sale-status-td .sale-status.proposal {
  background: #F2C94C;
  color: black;
}

.venue-settings main {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 480px;
}
.venue-settings main .copy-text-wrapper {
  display: flex;
  gap: 8px;
}
.venue-settings main .copy-text-wrapper span {
  color: green;
}
.venue-settings details summary {
  cursor: pointer;
}

.package-tab .horizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.package-tab .edit {
  height: 24px;
}
.package-tab .packages {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  margin: 32px 0;
}
.package-tab .packages .package .package-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.package-tab .packages .package .package-header h3 {
  margin: 0;
  display: flex;
  align-items: center;
}
.package-tab .packages .package .package-header h3 img {
  height: 16px;
  margin-right: 12px;
  transform: rotate(180deg);
}
.package-tab .packages .package .package-header h3 img.open {
  transform: rotate(0);
}
.package-tab .packages .package .package-details p {
  max-width: 600px;
}
.package-tab .packages .package .package-details table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  margin: 32px 16px;
}
.package-tab .packages .package .package-details table thead {
  background: rgba(237, 236, 236, 0.1333333333);
}
.package-tab .packages .package .package-details table td {
  padding: 8px 0;
}
.package-tab .packages .package .package-details table tbody > tr {
  cursor: pointer;
}
.package-tab .packages .package .package-details table tbody > tr:hover {
  background: #edecec;
}

/*# sourceMappingURL=tab-styles.css.map */
