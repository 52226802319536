.range-input .title-label {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
}
.range-input .input[type=range] {
  width: calc(100% - 4px);
}
.range-input .rotation-wrapper {
  display: flex;
}
.range-input .rotation-wrapper input {
  flex: 8;
}
.range-input .rotation-wrapper .curr-value {
  margin-left: 4px;
  flex-shrink: 1;
}

/*# sourceMappingURL=range-input.css.map */
