.client-header {
  width: 100%;
  padding: 8px 0;
  display: flex;
}
.client-header .grower {
  flex-grow: 1;
}

/*# sourceMappingURL=client-header.css.map */
