.burger-menu {
  position: relative;
  width: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgb(238, 239, 245);
  flex-shrink: 0;
}
.burger-menu img {
  width: 100%;
}
.burger-menu .closed-menu {
  z-index: 1;
}
.burger-menu .open-menu {
  position: absolute;
  width: 350px;
  background: whitesmoke;
  right: 0;
  top: 0;
}
.burger-menu .open-menu p {
  padding: 16px 32px;
  margin: 0;
}
.burger-menu .open-menu p:not(:last-of-type) {
  border-bottom: 1px solid lightgrey;
}
.burger-menu .open-menu p:hover {
  background: white;
  transition: 200ms background-color;
}
.burger-menu .open-menu input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.burger-menu:hover {
  background-color: whitesmoke;
  transition: 400ms background-color;
}

@media print {
  .burger-menu {
    display: none;
  }
}

/*# sourceMappingURL=burger-menu.css.map */
