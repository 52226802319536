.admin-page {
  width: 100%;
  height: 100%;
  display: flex;
}
.admin-page .left-menu {
  flex: 2;
  min-width: 150px;
  height: calc(100% - 60px);
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.admin-page .left-menu .grower {
  flex-grow: 1;
}
.admin-page .left-menu li {
  font-size: 1.2em;
  list-style: none;
  margin-bottom: 12px;
  cursor: pointer;
}
.admin-page .left-menu li:hover {
  border-bottom: 1px solid black;
}
.admin-page .left-menu .selected {
  font-weight: bold;
  border-bottom: 1px solid black;
}
.admin-page main {
  flex: 10;
  overflow-y: auto;
}
.admin-page main .search-area {
  margin: 30px 5px;
  padding: 16px;
  background: white;
}
.admin-page main .search-area .search-field input {
  font-size: 1em;
}
.admin-page main .search-area table {
  width: 550px;
  border-collapse: collapse;
  text-align: left;
}
.admin-page main .search-area table td {
  font-size: 1em;
}
.admin-page main .user {
  text-align: left;
}
.admin-page main .user label {
  font-weight: bold;
}
.admin-page main .user h3 {
  margin-top: 0;
}
.admin-page main .venue table tr:hover {
  border-bottom: 1px solid black;
}

/*# sourceMappingURL=admin.css.map */
