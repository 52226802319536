.pop-out-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #f8f8f8;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 16px;
  overflow-y: auto;
}
.pop-out-container .horizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pop-out-container .close-popout {
  background: black;
  border-radius: 100%;
  padding: 2px;
  opacity: 0.4;
  cursor: pointer;
}
.pop-out-container .close-popout:hover {
  transition: opacity 0.2s;
  opacity: 1;
}
.pop-out-container .simple-button, .pop-out-container .simple-input {
  margin-top: 16px;
}

/*# sourceMappingURL=side-popout.css.map */
