.power-table {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid lightgray;
}
.power-table table {
  margin-bottom: 15px;
}
.power-table table th:nth-child(2n+3), .power-table table td:nth-child(2n+3) {
  background: rgb(240, 240, 240);
}
.power-table table th {
  position: sticky;
  top: 0;
  cursor: pointer;
  background: white;
  padding-right: 4px;
  padding-left: 5px;
}
.power-table table th svg {
  width: 12px;
  display: none;
  margin-left: 2px;
}
.power-table table th svg.focus {
  display: inline-block;
}
.power-table table th svg.desc {
  transform: rotate(180deg);
}
.power-table table tr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid transparent;
}
.power-table table tr .delete-icon-td {
  width: 16px;
  opacity: 0;
}
.power-table table tr .delete-icon-td .delete-icon {
  border: 1px solid transparent;
  width: 14px;
  padding: 2px;
}
.power-table table tr .delete-icon-td:hover .delete-icon {
  border: 1px solid red;
  border-radius: 100%;
}
.power-table table tr td {
  border-right: 1px solid black;
  padding-right: 6px;
  padding-left: 6px;
}
.power-table table tr td .count-icon {
  display: inline-block;
  font-family: "Arima Madurai", "Raleway", "sans-serif";
  border-radius: 100%;
  margin-left: 3px;
  margin-right: 3px;
}
.power-table table tr:hover {
  border-bottom: 1px solid black;
}
.power-table table tr:hover .delete-icon-td {
  opacity: 1;
}
.power-table table input {
  height: 32px !important;
}
.power-table table input[type=checkbox] {
  width: 45px;
}
.power-table .save-button {
  position: sticky;
  bottom: 0;
}

/*# sourceMappingURL=power-table.css.map */
