.fancy-border-outer {
  position: absolute;
  padding: 8px;
  background: white;
  min-width: 300px;
}
.fancy-border-outer .lock, .fancy-border-outer .close {
  position: absolute;
  top: 14px;
  left: 14px;
  height: 18px;
  padding: 2px;
  border-radius: 100%;
  cursor: pointer;
}
.fancy-border-outer .close {
  right: 14px;
  left: auto;
  background: lightgray;
}
.fancy-border-outer .lock:hover, .fancy-border-outer .close:hover {
  background: lightgray;
}
.fancy-border-outer .fancy-border-inner {
  border: 2px solid #dbad31;
  padding: 16px;
}
.fancy-border-outer .fancy-border-inner h1, .fancy-border-outer .fancy-border-inner h2, .fancy-border-outer .fancy-border-inner h3, .fancy-border-outer .fancy-border-inner h4, .fancy-border-outer .fancy-border-inner h5 {
  width: 100%;
  text-align: center;
  margin: 0 0 16px;
  padding: 0 0 4px;
  border-bottom: 1px solid #dbad31;
}
.fancy-border-outer .drag-catcher {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.locked .fancy-border-inner {
  border-width: 0.5px;
}

/*# sourceMappingURL=movable-window.css.map */
