.oval {
  border-radius: 100%;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

/*# sourceMappingURL=oval.css.map */
