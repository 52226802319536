.table-template-header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.table-template-header img {
  height: 20px;
  cursor: pointer;
  background: #073350;
}
.table-template-header img.editing {
  width: 20px;
  padding: 2px;
  border-radius: 50%;
}

.table-template-menu {
  position: relative;
}
.table-template-menu .model-list {
  height: 450px;
  overflow-y: auto;
  scrollbar-color: white #777;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.table-template-menu .model-list .table-3d-model-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  position: relative;
}
.table-template-menu .model-list .table-3d-model-wrapper .table-info {
  flex-grow: 1;
}
.table-template-menu .model-list .table-3d-model-wrapper .table-info > * {
  margin: 0;
}
.table-template-menu .model-list .table-3d-model-wrapper .table-edit {
  display: flex;
  align-items: center;
  padding-right: 16px;
}
.table-template-menu .model-list .table-3d-model-wrapper .table-edit img {
  height: 20px;
  width: 20px;
}
.table-template-menu .model-list .table-3d-model-wrapper canvas {
  border-radius: 8px;
}
.table-template-menu .create-new-table-background {
  position: absolute;
  bottom: 0px;
  width: 100%;
  backdrop-filter: blur(1.4px);
}
.table-template-menu .create-new-table-background .create-new-table-pop-btn {
  cursor: pointer;
  margin: 0 auto;
  border-radius: 5px;
  background: #0059D4;
  padding: 16px 18px;
  text-align: center;
  width: 70%;
  color: white;
  font-weight: bold;
}
.table-template-menu .create-new-table-background .create-new-table-pop-btn:hover {
  background: #0b67e3;
}

/*# sourceMappingURL=table-template-menu.css.map */
