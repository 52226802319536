.distance-line-point {
  position: absolute;
  width: 5px;
  height: 5px;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
}
.distance-line-point:hover {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.distance-line-text {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  opacity: 0.6;
}
.distance-line-text p {
  padding: 0;
  margin: 0;
}

/*# sourceMappingURL=distance-line.css.map */
