.mouse-event-handler {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

/*# sourceMappingURL=mouse-event-handler.css.map */
