.signup-invitation-list {
  width: 100%;
  min-height: 550px;
  display: flex;
  flex-direction: column;
}
.signup-invitation-list .create-invitation {
  margin: 32px 0;
}
.signup-invitation-list table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.signup-invitation-list table td:nth-of-type(2n+1), .signup-invitation-list table th:nth-of-type(2n+1) {
  background: rgba(156, 200, 151, 0.15);
}
.signup-invitation-list table th:nth-of-type(4), .signup-invitation-list table td:nth-of-type(4) {
  border-right: 4px double black;
}
.signup-invitation-list table th, .signup-invitation-list table td {
  padding-left: 4px;
}

/*# sourceMappingURL=signup-invitation-list.css.map */
